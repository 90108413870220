import axios from "@/http/http";
import {Module, ActionTree} from "vuex";
import * as qs from "qs";

const actions: ActionTree<any, any> = {
    getJobSectors({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {

            commit("SET_LOADING", true);
            try {
                const config = {
                    method: 'get',
                    url: 'https://www.tnprivatejobs.tn.gov.in/api/Naanmudhalvan/get_master_sector_details',
                    headers: {
                        'X-API-KEY': 'c44sco0k8k0wcssog48kcow8c0ksgcgws4s8ccgc',
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    data
                };

                const response = await axios(config)

                commit("setJobsSectorsData", response.data);
                resolve(response.data);
            } catch (err: any) {
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
            commit("SET_LOADING", false);
        });
    },
    getJobCompanies({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {

            commit("SET_LOADING", true);
            try {
                const queryData = qs.stringify({
                    'sectorid': data.sectorId
                });

                const config = {
                    method: 'post',
                    url: 'https://www.tnprivatejobs.tn.gov.in/api/Naanmudhalvan/sectorwisejob',
                    headers: {
                        'X-API-KEY': 'c44sco0k8k0wcssog48kcow8c0ksgcgws4s8ccgc',
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    data: queryData,
                };

                const response = await axios(config)

                commit("setSectorCompaniesData", response.data);
                resolve(response.data);
            } catch (err: any) {
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
            commit("SET_LOADING", false);
        });
    },
    getJobCompanyJobs({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {

            commit("SET_LOADING", true);
            try {
                const queryData = qs.stringify({
                    'sectorid': data.sectorId,
                    'companyid': data.companyId,
                });

                const config = {
                    method: 'post',
                    url: 'https://www.tnprivatejobs.tn.gov.in/api/Naanmudhalvan/company_jobs_with_sector',
                    headers: {
                        'X-API-KEY': 'c44sco0k8k0wcssog48kcow8c0ksgcgws4s8ccgc',
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    data: queryData,
                };

                const response = await axios(config)

                commit("setCompanyJobsData", response.data);
                resolve(response.data);
            } catch (err: any) {
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
            commit("SET_LOADING", false);
        });
    },
};
const jobsStore: Module<any, any> = {
    state: () => ({
        jobSectors: [],
        jobsSectorsComingSoon: [],
        currentAcceptedSectors: [
            "Aerospace & Aviation",
            "Automotive",
            "Banking, Financial Services and Insurance",
            "Construction",
            "Electronics & Hardware",
            "IT-ITES",
            "Leather",
            "Logistics",
            "Manufacturing",
            "Telecom"
        ],
        sectorCompanies: [],
        companyJobs: [],
    }),
    mutations: {
        setSectorCompaniesData(state, data) {
            state.sectorCompanies = data.sector_company
        },
        setCompanyJobsData(state, data) {
            state.companyJobs = data.job_details
        },
        setJobsSectorsData(state, data) {
            let sectorsData: any = [];
            let comingSoonSectors: any = [];
            const sectors = data.master_sector;
            sectors.forEach((sector: any) => {
                if (state.currentAcceptedSectors.includes(sector.sector_name)) {
                    sectorsData.push(sector);
                } else {
                    comingSoonSectors.push(sector);
                }
            });
            state.jobSectors = sectorsData;
            state.jobsSectorsComingSoon = comingSoonSectors;
        }
    },
    actions,
    getters: {
        jobSectors(state) {
            return state.jobSectors;
        },
        jobsSectorsComingSoon(state) {
            return state.jobsSectorsComingSoon;
        },
        sectorCompanies(state) {
            return state.sectorCompanies;
        },
        companyJobs(state) {
            return state.companyJobs;
        }
    },
};

export default jobsStore;
