import axios from "@/http/http";
import { Module, ActionTree } from "vuex";
import moment from "moment";

const actions: ActionTree<any, any> = {
  fetchPlacements({commit, dispatch}, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/placements/list/`, { params: data });
        commit('savePlacements', response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  fetchTestBatch({commit, dispatch}, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/placements/student/test/details/`, { params: data });
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  joinBatchAndGetExamToken({commit, dispatch}, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(`https://exam.radiusedutech.com/join/${data.batch}`, data.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  attachStudentToTest({commit, dispatch}, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      try {
        const response = await axios.post(`/api/v1/placements/student/test/`, formData);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  fetchPublicPlacements({commit, dispatch}, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/placements/list/public`, { params: data });
        commit('savePlacements', response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  fetchAppliedPlacements({commit, dispatch}, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/placements/student/list/`, { params: data });
        commit('saveAppliedPlacements', response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  addNewPlacement({commit, dispatch}, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });

      commit("SET_LOADING", true);
      try {
        const response = await axios.post(`/api/v1/placements/add/`, formData);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  updatePlacement({commit, dispatch}, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });

      commit("SET_LOADING", true);
      try {
        const response = await axios.patch(`/api/v1/placements/update/`, formData);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  applyForPlacement({commit, dispatch}, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });

      commit("SET_LOADING", true);
      try {
        const response = await axios.post(`/api/v1/placements/student/add/`, formData);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
};
const placementsStore: Module<any, any> = {
  state: () => ({
    placements: [],
    appliedPlacements: [],
    placementsCount: 0,
    placementsTotalPages: 0,
    placementsLimit: 20,
    placementsPage: 0,
  }),
  mutations: {
    savePlacements(state, data: any) {
      state.placements = data.placements_list;

      state.placementsCount = data.total_count
      state.placementsTotalPages = Math.ceil(data.total_count / data.limit);
      state.placementsLimit = data.limit;
      state.placementsPage = data.page;
    },
    saveAppliedPlacements(state, data: any) {
      state.appliedPlacements = data.placements_list;

      state.placementsCount = data.total_count
      state.placementsTotalPages = Math.ceil(data.total_count / data.limit);
      state.placementsLimit = data.limit;
      state.placementsPage = data.page;
    },
  },
  actions,
  getters: {
    placementList(state) {
        return state.placements;
    },
    placementPage(state) {
        return state.placementsPage;
    },
    placementTotalPages(state) {
        return state.placementsTotalPages;
    },
    appliedPlacementsList(state) {
        return state.appliedPlacements;
    }
  },
};

export default placementsStore;
