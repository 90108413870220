<template>
  <!-- Add NavBar -->
  <div
      :class="{
    'loading-blur': isLoading,
      }"
      class="w-full">
    <router-view/>
    <l-loading></l-loading>
  </div>
</template>
<script>
import LLoading from "@/components/custom/loading/LLoading";
import {mapGetters} from "vuex";
export default {
  components: { LLoading },
  computed: {
    ...mapGetters(["isLoading", "activeUser"]),
  },
  watch: {
    activeUser(newValue, oldValue) {
      this.checkIfCollegePaymentInRenewal()
    },
  },
  data() {
    return {
      isInRenewal: true,
    };
  },
  methods: {
    checkIfCollegePaymentInRenewal() {
      this.isInRenewal = true;
    },
  },
  mounted() {
    this.checkIfCollegePaymentInRenewal();
  },
  created() {
    this.$store.dispatch("checkLoginStatus");
    this.$store.dispatch("getRegistrationDropDowns");
    this.$store.dispatch("getAffiliatedUniversities");
    this.$store.dispatch("getDistrictsData");
  },
};
</script>
<style lang="scss">
</style>
