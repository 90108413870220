<template>
  <TransitionRoot appear :show="isLoading" as="div">

    <div id="loading-overlay" class="fixed inset-0 z-50 loading-parent overflow-y-auto">
      <div class="min-h-screen px-4 text-center">
      </div>
    </div>
  </TransitionRoot>
</template>

<script>
import { TransitionRoot, TransitionChild } from "@headlessui/vue";
import { mapGetters } from "vuex";

export default {
  name: "LLoading",
  components: {
    TransitionRoot,
    TransitionChild,
  },
  computed: {
    ...mapGetters(["isLoading"]),
  },
};
</script>

<style>
.loading-blur :not(#loading-overlay):not(#loading-overlay *) {
  filter: blur(5px);
}
.loading-parent {
  background-color: rgba(0, 0, 0, 0.1);
}
</style>
