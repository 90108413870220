import axios from "@/http/http";
import {Module, ActionTree} from "vuex";

const actions: ActionTree<any, any> = {
    checkUsername({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios.get("/api/v1/data/check-username/", {
                    params: data,
                });
                resolve(response.data);
            } catch (err: any) {
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    },
    getRegistrationDropDowns({commit, dispatch}) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            commit("SET_LOADING", true);
            if (localStorage.getItem('management_types') && localStorage.getItem('college_types') && localStorage.getItem('branches')) {
                // @ts-ignore
                commit('saveManagementTypes', JSON.parse(localStorage.getItem('management_types')));
                // @ts-ignore
                commit('saveCollegeTypes', JSON.parse(localStorage.getItem('college_types')));
                // @ts-ignore
                commit('saveBranches', JSON.parse(localStorage.getItem('branches')));
            } else {
                try {
                    const response = await axios.get(`/api/v1/data/registration-dropdowns/`);
                    commit('saveManagementTypes', response.data.management_types);
                    commit('saveCollegeTypes', response.data.college_types);
                    commit('saveBranches', response.data.branches);
                    resolve(response.data);
                } catch (err: any) {
                    try {
                        reject(err.response.data.message);
                    } catch (e) {
                        reject(err.message);
                    }
                }
            }
            commit("SET_LOADING", false);
        });
    },
    getAffiliatedUniversities({commit, dispatch}) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            // commit("SET_LOADING", true);
            try {
                const response = await axios.get(`/api/v1/data/affiliated-universities/`);
                commit('saveAffiliatedUniversities', response.data);
                resolve(response.data);
            } catch (err: any) {
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
            // commit("SET_LOADING", false);
        });
    },
    getDistrictsData({commit, dispatch}) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            commit("SET_LOADING", true);
            if (localStorage.getItem('districts')) {
                // @ts-ignore
                commit('saveDistricts', JSON.parse(localStorage.getItem('districts')));
            } else {
                try {
                    const response = await axios.get(`/api/v1/data/districts/`);
                    commit('saveDistricts', response.data);
                    resolve(response.data);
                } catch (err: any) {
                    try {
                        reject(err.response.data.message);
                    } catch (e) {
                        reject(err.message);
                    }
                }
            }
            commit("SET_LOADING", false);
        });
    },
    getDropDownCollegesList({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        //
        return new Promise(async (resolve, reject) => {
            commit("SET_LOADING", true);
            // if (localStorage.getItem('colleges')) {
            //     // @ts-ignore
            //     commit('saveColleges', JSON.parse(localStorage.getItem('colleges')));
            // } else {
                let queryParams = {};
                if(localStorage.getItem('da-district')) {
                    queryParams = {
                        'district': localStorage.getItem('da-district'),
                    }
                }
                try {
                    const response = await axios.get(`/api/v1/college/dropdown-list/`, {
                        params: queryParams
                    });
                    commit('saveColleges', response.data);
                    resolve(response.data);
                } catch (err: any) {
                    try {
                        reject(err.response.data.message);
                    } catch (e) {
                        reject(err.message);
                    }
                }
            // }
            commit("SET_LOADING", false);
        });
    },
    getStateTownFromPinCode({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        //
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios.get(`https://api.postalpincode.in/pincode/${data}`);
                resolve(response.data);
            } catch (err: any) {
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    }
};
const commonStore: Module<any, any> = {
    state: () => ({
        managementTypes: {},
        collegeTypes: {},
        branches: [],
        affiliatedUniversities: [],
        districts: [],
        colleges: [],
    }),
    mutations: {
        saveManagementTypes(state, data: any) {
            localStorage.setItem("management_types", JSON.stringify(data));
            state.managementTypes = data;
        },
        saveCollegeTypes(state, data: any) {
            localStorage.setItem("college_types", JSON.stringify(data));
            state.collegeTypes = data;
        },
        saveBranches(state, data: any) {
            localStorage.setItem("branches", JSON.stringify(data));
            state.branches = data;
        },
        saveAffiliatedUniversities(state, data: any) {
            state.affiliatedUniversities = data;
        },
        saveDistricts(state, data: any) {
            localStorage.setItem("districts", JSON.stringify(data));
            state.districts = data;
        },
        saveColleges(state, data: any) {
            localStorage.setItem("colleges", JSON.stringify(data));
            state.colleges = data.colleges_list;
        },
    },
    actions,
    getters: {
        managementTypes(state) {
            return state.managementTypes;
        },
        collegeTypes(state) {
            return state.collegeTypes;
        },
        branches(state) {
            return state.branches;
        },
        affiliatedUniversities(state) {
            return state.affiliatedUniversities;
        },
        districts(state) {
            return state.districts;
        },
        colleges(state) {
            return state.colleges;
        }
    },
};

export default commonStore;
