import axios from "@/http/http";
import { Module, ActionTree } from "vuex";

const actions: ActionTree<any, any> = {
  fetchAllSpecialCourses({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/special-courses/list/`, { params: data });
        commit('setSpecialCoursesData', response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  deleteSpecialCourses({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      commit("SET_LOADING", true);
      try {
        const response = await axios.delete(`/api/v1/special-courses/delete/`, { params: data });
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  createSpecialCourse({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });

      commit("SET_LOADING", true);
      try {
        const response = await axios.post(`/api/v1/special-courses/add/`, formData);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  editSpecialCourse({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });

      commit("SET_LOADING", true);
      try {
        const response = await axios.patch(`/api/v1/special-courses/add/`, formData);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
};
const taskAdminStore: Module<any, any> = {
  state: () => ({
    specialCourses: [],
    sCPage: 0,
    sCTotalPages: 0,
  }),
  mutations: {
    setSpecialCoursesData(state, data) {
      state.specialCourses = data.special_courses;
      state.sCPage = data.page;
      state.sCTotalPages = Math.ceil(data.count / data.limit);
    },
  },
  actions,
  getters: {
    adminSpecialCourses(state) {
        return state.specialCourses;
    },
    adminSCPage(state) {
        return state.sCPage;
    },
    adminSCTotalPages(state) {
        return state.sCTotalPages;
    },
  },
};

export default taskAdminStore;
