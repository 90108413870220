import { createStore } from "vuex";
import authStore from "@/store/auth/authStore";
import profileStore from "@/store/profile/profileStore";
import collegeStore from "@/store/college/collegeStore";
import collegeCoursesStore from "@/store/college/collegeCoursesStore";
import commonStore from "@/store/common/commonStore";
import taskAdminStore from "@/store/admin/taskAdminStore";
import taskAdminSpecialCoursesStore from "@/store/admin/taskAdminSpecialCoursesStore";
import adminStore from "@/store/admin/adminStore";
import studentStore from "@/store/student/studentStore";
import studentCoursesStore from "@/store/student/studentCoursesStore";
import studentCoursesOldStore from "@/store/student/studentCoursesOldStore";
import jobsStore from "@/store/jobs/jobsStore";
import rmcmStore from "@/store/rmcm/rmcmStore";
import placementsStore from "@/store/placements/placementsStore";

import router from "@/router";
import commonUpdateStore from "@/store/common/commonUpdateStore";
import adminReportsStore from "@/store/admin/adminReportsStore";
export default createStore({
  state: {
    isLoggedIn: false,
    isLoading: false,
    activeUser: {},
  },
  mutations: {
    SET_LOGGED_IN(state, value) {
      state.isLoggedIn = value;
    },
    SET_LOADING(state, value) {
      state.isLoading = value;
    },
    SET_USER_DATA(state) {
      try {
        const user = localStorage.getItem("user");
        state.activeUser = JSON.parse(user || "{}");
        console.log(state.activeUser);
      } catch (e) {
        state.activeUser = {};
      }
    },
  },
  getters: {
    isLoggedIn(state) {
      return state.isLoggedIn;
    },
    isLoading(state) {
      return state.isLoading;
    },
    activeUser(state) {
      return state.activeUser;
    },
  },
  actions: {
    downloadJSONToCSV(state, {data, filename}) {
      const replacer = (key: any, value: any) => value === null ? '' : value // specify how you want to handle null values here
      const header = Object.keys(data[0])
      let csv: any = data.map((row: any) => header.map((fieldName: string) =>
          JSON.stringify(row[fieldName], replacer)).join(','))
      csv.unshift(header.join(','))
      csv = csv.join('\r\n')

      // Create link and download
      const link = document.createElement('a');
      link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(csv));
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    checkLoginStatus({ commit, dispatch }) {
      const token = localStorage.getItem("token");
      if (token) {
        commit("SET_LOGGED_IN", true);
        dispatch("fetchProfileDetails");
        dispatch("loadDistrict");
        commit("SET_USER_DATA");
      } else {
        commit("SET_LOGGED_IN", false);
        commit("SET_USER_DATA");
      }
    },
    logout({ commit }, data) {
      localStorage.clear();
      commit("SET_LOGGED_IN", false);
      commit("SET_USER_DATA");
      if(!data) {
        router.push("/");
        window.location.reload();
      }
    },
  },
  modules: {
    authStore,
    profileStore,
    collegeStore,
    collegeCoursesStore,
    commonStore,
    taskAdminStore,
    adminStore,
    taskAdminSpecialCoursesStore,
    studentStore,
    studentCoursesStore,
    studentCoursesOldStore,
    jobsStore,
    rmcmStore,
    placementsStore,
    commonUpdateStore,
    adminReportsStore,
  },
});
